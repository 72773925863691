import React, { useMemo } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import * as Routes from '@constants/route-map';
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@toolbox/constants/local-storage';
import { Login } from "@views/Login"
import { Home } from "@views/Home"
import { PrivateRoute, AuthRoute } from './PrivateRoute';
// ConsullBallot aún no está siendo llaamdo
import { IpPermised } from '@/views/ipPermised';
import { IpDenied } from '@/views/ipDenied';

const AllRoutes: React.FC = () => {

	const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);

   const moduleHome = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_HOME} component={Home} />,
   ];

   const moduleIpPermised = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_IP_PERMISED} component={IpPermised} />,
   ];

   const moduleIpDenied = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_IP_DENIED} component={IpDenied} />,
   ];



	const routes = useMemo(() => {
      return (
         <Router>
            <Switch>
               {moduleIpDenied}
               {moduleIpPermised}
               <AuthRoute exact path={Routes.ROUTE_LOGIN} component={Login} />
               {<Route path='*' exact={true} component={() => {
                  return <Redirect to={Routes.ROUTE_LOGIN} />
               }} />}
            </Switch>
         </Router>
      )
	},// eslint-disable-next-line
	[JSON.stringify(dataUser)]);

	return routes;
}

export default AllRoutes;
